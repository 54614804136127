import gsap from "gsap";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import slugify from "slugify";
import AmenitiesIcons from "./amenities-icons";

const FacilitiesBlock = ({ array }) => {
	return (
		<>
			<div>
				<div className={`bg-white w-100 position-relative `}>
					<Row>
						{array?.map((item) => {
							return (
								<Col xs={3} md={2} xxl={2}>
									<div className="text-center">
										<h2
											className="text-green fs-1"
											style={{ fontSize: "60px" }}
										>
											<AmenitiesIcons cleaningWidth="40px" lookupValue={item} />
										</h2>
										<p className="text-black">{item}</p>
									</div>
								</Col>
							);
						})}
					</Row>
				</div>
			</div>
		</>
	);
};

export default FacilitiesBlock;

// extracted by mini-css-extract-plugin
export var bakerloo = "stations-tags-module--bakerloo--ddfdc";
export var bakerlooFontColor = "stations-tags-module--bakerloo-font-color--68292";
export var basic = "stations-tags-module--basic--7a95f";
export var cableCar = "stations-tags-module--cable-car--b7e2d";
export var cableCarFontColor = "stations-tags-module--cable-car-font-color--1afea";
export var central = "stations-tags-module--central--f400c";
export var centralFontColor = "stations-tags-module--central-font-color--cb897";
export var circle = "stations-tags-module--circle--ac144";
export var circleFontColor = "stations-tags-module--circle-font-color--5592b";
export var crossrail = "stations-tags-module--crossrail--5dc12";
export var crossrailFontColor = "stations-tags-module--crossrail-font-color--0f9dd";
export var district = "stations-tags-module--district--a86a5";
export var districtFontColor = "stations-tags-module--district-font-color--750fb";
export var dlr = "stations-tags-module--dlr--c54ec";
export var dlrFontColor = "stations-tags-module--dlr-font-color--93038";
export var elizabeth = "stations-tags-module--elizabeth--0b438";
export var elizabethFontColor = "stations-tags-module--elizabeth-font-color--d72a7";
export var hammersmithCity = "stations-tags-module--hammersmith-city--1f811";
export var hammersmithCityFontColor = "stations-tags-module--hammersmith-city-font-color--b1cb3";
export var jubilee = "stations-tags-module--jubilee--db206";
export var jubileeFontColor = "stations-tags-module--jubilee-font-color--4f386";
export var metropolitan = "stations-tags-module--metropolitan--42684";
export var metropolitanFontColor = "stations-tags-module--metropolitan-font-color--b495a";
export var nameSpan = "stations-tags-module--name-span--a7c2b";
export var northern = "stations-tags-module--northern--2df16";
export var northernFontColor = "stations-tags-module--northern-font-color--b1a72";
export var overground = "stations-tags-module--overground--d0b15";
export var overgroundFontColor = "stations-tags-module--overground-font-color--d72ac";
export var piccadilly = "stations-tags-module--piccadilly--6cf5e";
export var piccadillyFontColor = "stations-tags-module--piccadilly-font-color--03665";
export var tramlink = "stations-tags-module--tramlink--5e6d3";
export var tramlinkFontColor = "stations-tags-module--tramlink-font-color--4145e";
export var victoria = "stations-tags-module--victoria--59eff";
export var victoriaFontColor = "stations-tags-module--victoria-font-color--9b6e5";
export var waterlooCity = "stations-tags-module--waterloo-city--b4fc5";
export var waterlooCityFontColor = "stations-tags-module--waterloo-city-font-color--fb31b";
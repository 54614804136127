import React from "react";
import { FaPhone, FaEnvelope } from "react-icons/fa";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const TeamMemberCard = ({ teamMember }) => {
	const { name, email, phoneNumbers, featuredImage } =
		teamMember?.teamMemberFields || {};

	// Get the GatsbyImage data
	const image = getImage(
		featuredImage?.localFile?.childImageSharp?.gatsbyImageData
	);

	return (
		<div className="p-3 mb-3" style={styles.cardContainer}>
			{/* Team Member Image */}
			<div style={styles.imageContainer}>
				<GatsbyImage
					image={image}
					alt={featuredImage.altText}
					style={styles.circularImage}
					imgStyle={styles.imageStyle} // Custom style for inner image
				/>
			</div>

			{/* Team Member Details */}
			<div style={styles.detailsContainer}>
				<h4 style={styles.name}>{name}</h4>
				<div style={styles.contactInfo}>
					{/* Phone Number (clickable link) */}
					{phoneNumbers?.[0]?.number && (
						<div style={styles.contactItem}>
							<FaPhone style={styles.icon} />
							<a href={`tel:${phoneNumbers[0].number}`} style={styles.link}>
								{phoneNumbers[0].number}
							</a>
						</div>
					)}

					{/* Email (clickable link) */}
					{email && (
						<div style={styles.contactItem}>
							<FaEnvelope style={styles.icon} />
							<a href={`mailto:${email}`} style={styles.link}>
								{email}
							</a>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

// Styles
const styles = {
	cardContainer: {
		display: "flex", // Align image and text side by side
		alignItems: "center", // Vertically align image and text

		border: "1px solid #D3D800", // Yellow border
		borderRadius: "12px",
		backgroundColor: "#fff",
		maxWidth: "fit-content", // Ensure the width fits the content only
		margin: "10px 0", // Optional margin around the card
	},
	imageContainer: {
		marginRight: "10px", // Space between the image and text
	},
	circularImage: {
		width: "80px", // Set fixed width
		height: "80px", // Set fixed height
		borderRadius: "50%", // Circular image
		objectFit: "cover",
	},
	imageStyle: {
		borderRadius: "50%", // Ensure the inner image is circular
	},
	detailsContainer: {
		display: "flex",
		flexDirection: "column",
	},
	name: {
		margin: "0 0 5px 0",
		color: "#333",
		fontSize: "18px",
		fontWeight: "bold",
	},
	contactInfo: {
		display: "flex",
		flexDirection: "column",
		gap: "5px",
	},
	contactItem: {
		display: "flex",
		alignItems: "center",
		color: "#333",
	},
	icon: {
		color: "#3AAA35", // Green icon color
		marginRight: "8px",
	},
	link: {
		color: "#333", // Ensure the link is not blue
		textDecoration: "none",
	},
};

export default TeamMemberCard;

// src/components/PropertyDescription.js

import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Badge, Button, Modal } from "react-bootstrap";
import FacilitiesBlock from "./facilities-block";
import {
	FaBuilding,
	FaHeart,
	FaMapMarkerAlt,
	FaPlay,
	FaRegHeart,
	FaRegEdit,
} from "react-icons/fa";
import ImageSliderNew from "./image-slider-new";
import CartContext from "../context/CartContext";
import { StaticImage } from "gatsby-plugin-image";
import RequestQuoteForm from "./request-quote-form";
import RequestQuoteFormMultiStep from "./request-quote-form-multi-step";
import BookViewingForm from "./book-a-viewing-form";
import BookViewingFormMultiStep from "./book-a-viewing-form-multi-step";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link } from "gatsby";

const PropertyDetailHero = ({ property }) => {
	const {
		subwayStation1,
		subwayStation1DistanceKm,
		subwayStation1DurationMins,
		subwayStation1Lines,
		subwayStation2,
		subwayStation2DistanceKm,
		subwayStation2DurationMins,
		subwayStation2Lines,
		subwayStation3,
		subwayStation3DistanceKm,
		subwayStation3DurationMins,
		subwayStation3Lines,
		airtableId,
		locationLatitude,
		locationLongitude,
		name: propertyName,
		services,
		virtualTourLink,
		amenities,
		type,
		desksFrom,
		rentPM,
		floorsAvailable,
		desksTo,
		features,
		description,
		addressLineOne,
		addressLineTwo,
		city,
		postCode,
		live,
		photos,
		videoId,
		fixedId,
	} = property;

	// Convert comma-separated strings to arrays
	const featureList = features ? features.map((item) => item.trim()) : [];

	const {
		cart,
		onLocationChangeScroll,
		getDistanceFromLatLonInKm,
		OnAddToCart,
		OnRemoveFromCart,
		distancePoint,
		selectedLocation,
		budgetPerDesk,
		numberOfPeople,
	} = useContext(CartContext);

	const handleHeartClick = (clickEvent, shortlistProp, addOrRemove) => {
		clickEvent.preventDefault();
		addOrRemove(shortlistProp);
	};

	function round5(x) {
		return Math.ceil(x / 5) * 5;
	}

	const [vidShow, setVidShow] = useState(false);

	const handleVidShow = () => setVidShow(true);
	const handleVidClose = () => setVidShow(false);

	const [vidShow2, setVidShow2] = useState(false);

	const handleVidShow2 = () => setVidShow2(true);
	const handleVidClose2 = () => setVidShow2(false);

	const newPrice =
		property?.rentPM === null ||
		property?.rentPM === undefined ||
		property?.rentPM === 0 ||
		property?.rentPM === 0.0
			? "£POA"
			: `£${round5(property?.rentPM)}pcm`;

	// State variables for facilities display
	const [visibleFeaturesCount, setVisibleFeaturesCount] = useState(10);
	const [increment, setIncrement] = useState(10);

	useEffect(() => {
		if (typeof window !== "undefined") {
			const width = window.innerWidth;
			let initialCount = 6;
			let newIncrement = 6;

			if (width <= 767) {
				initialCount = 4;
				newIncrement = 4;
			} else if (width >= 768 && width <= 1200) {
				initialCount = 6;
				newIncrement = 6;
			} else {
				initialCount = 6;
				newIncrement = 6;
			}

			setVisibleFeaturesCount(initialCount);
			setIncrement(newIncrement);
		}
	}, []);

	const handleViewMore = () => {
		setVisibleFeaturesCount((prevCount) =>
			Math.min(prevCount + increment, featureList.length)
		);
	};

	return (
		<section>
			<Container className="d-lg-none py-4">
				<Row>
					<Col>
						<Link className="text-green" to="/listings">
							<FaArrowLeftLong className="me-2" /> Back to listings
						</Link>
					</Col>
				</Row>
			</Container>

			{/* Modals for 'Book a viewing' and 'Request a quote' */}
			<Modal
				style={{ zIndex: 9999999, backgroundColor: "rgba(0, 0, 0, 0.2)" }}
				size="lg"
				show={vidShow}
				centered
				onHide={handleVidClose}
				className="w-100 p-4"
			>
				<div className="position-relative">
					<Modal.Body
						style={{
							overflow: "hidden",
							borderRadius: "12px",
						}}
						className="p-0 w-100"
						closeButton={false}
					>
						<div
							style={{
								borderBottomLeftRadius: "12px",
								borderBottomRightRadius: "12px",
							}}
							className="p-4 mb-3 bg-white w-100"
						>
							<Row>
								<Col>
									<h4 className="pb-0 text-center">Book a viewing</h4>
									<p>
										Fill in your contact details below and we'll schedule your
										viewing in the first available slot.
									</p>
								</Col>
							</Row>
							<div className="d-none d-lg-block">
								<BookViewingForm
									officeName={property?.name}
									subject="Book a viewing"
								/>
							</div>
							<div className="d-lg-none">
								<BookViewingFormMultiStep
									officeName={property?.name}
									subject="Book a viewing"
								/>
							</div>
							<Button
								onClick={handleVidClose}
								variant="outline-secondary"
								className="px-3 py-2 w-100 mt-2 text-black montserrat-bold"
							>
								Close
							</Button>
						</div>
					</Modal.Body>
				</div>
			</Modal>

			<Modal
				style={{ zIndex: 9999999, backgroundColor: "rgba(0, 0, 0, 0.2)" }}
				size="lg"
				show={vidShow2}
				centered
				onHide={handleVidClose2}
				className="w-100 p-4"
			>
				<div className="position-relative">
					<Modal.Body
						style={{
							overflow: "hidden",
							borderRadius: "12px",
						}}
						className="p-0 w-100"
						closeButton={false}
					>
						<div
							style={{
								borderBottomLeftRadius: "12px",
								borderBottomRightRadius: "12px",
							}}
							className="p-4 mb-3 bg-white w-100"
						>
							<Row>
								<Col>
									<h4 className="pb-0 text-center">Request a quote</h4>
									<p>
										Fill in your contact details below and we'll schedule your
										viewing in the first available slot.
									</p>
								</Col>
							</Row>
							<div className="d-none d-lg-block">
								<RequestQuoteForm
									officeName={property?.name}
									subject="Request a quote"
								/>
							</div>
							<div className="d-lg-none">
								<RequestQuoteFormMultiStep
									officeName={property?.name}
									subject="Request a quote"
								/>
							</div>
							<Button
								onClick={handleVidClose2}
								variant="outline-secondary"
								className="px-3 py-2 w-100 mt-2 text-black montserrat-bold"
							>
								Close
							</Button>
						</div>
					</Modal.Body>
				</div>
			</Modal>

			<ImageSliderNew imageDataArr={photos} height="80vh" className="" />
			<Container className="pt-4">
				<Row>
					<Col md={8}>
						<div className="mb-4">
							<div className="text-end">
								<div
									style={{ fontSize: "90%" }}
									className="d-md-none align-items-top text-med-grey ms-4 justify-content-between"
								>
									<>
										{cart?.filter((e) => e.airtableId === property?.airtableId)
											.length > 0 && (
											<button
												onClick={(e) =>
													handleHeartClick(e, property, OnRemoveFromCart)
												}
												className="fs-5 text-green p-0 border-0 bg-transparent"
											>
												<StaticImage
													src={"../images/heart.svg"}
													alt="Your Office People"
													height={24}
													layout="fixed"
												/>
											</button>
										)}
										{cart?.filter((e) => e.airtableId === property?.airtableId)
											.length < 1 && (
											<button
												onClick={(e) =>
													handleHeartClick(e, property, OnAddToCart)
												}
												className="fs-5 text-green p-0 border-0 bg-transparent"
											>
												<StaticImage
													src={"../images/heart-outline.svg"}
													alt="Your Office People"
													height={24}
													layout="fixed"
												/>
											</button>
										)}
									</>
								</div>
							</div>
							<h1 className="text-black display-5">{propertyName}</h1>
							<div className="d-flex align-items-center mb-4">
								<FaMapMarkerAlt className="text-green me-2" />{" "}
								<p className="text-black pb-0 mb-0">
									{`${addressLineOne ? `${addressLineOne}, ` : ""}${
										addressLineTwo ? `${addressLineTwo}, ` : ""
									}${city ? `${city}, ` : ""}${postCode ? `${postCode}` : ""}`}
								</p>
							</div>
							<div className="d-md-flex align-items-center">
								<Button
									onClick={handleVidShow}
									className={`w-100 w-md-auto fw-bold me-md-3`}
									variant="secondary"
								>
									Book a viewing
								</Button>
								<Button
									onClick={handleVidShow2}
									className={`mt-2 mt-md-0 w-100 w-md-auto fw-bold`}
									variant="transparent"
								>
									Request a quote
								</Button>
								<div
									style={{ fontSize: "90%" }}
									className="d-none d-md-flex align-items-top text-med-grey ms-4 justify-content-between"
								>
									<>
										{cart?.filter((e) => e.airtableId === property?.airtableId)
											.length > 0 && (
											<button
												onClick={(e) =>
													handleHeartClick(e, property, OnRemoveFromCart)
												}
												className="fs-5 text-green p-0 border-0 bg-transparent"
											>
												<StaticImage
													src={"../images/heart.svg"}
													alt="Your Office People"
													height={24}
													layout="fixed"
												/>
											</button>
										)}
										{cart?.filter((e) => e.airtableId === property?.airtableId)
											.length < 1 && (
											<button
												onClick={(e) =>
													handleHeartClick(e, property, OnAddToCart)
												}
												className="fs-5 text-green p-0 border-0 bg-transparent"
											>
												<StaticImage
													src={"../images/heart-outline.svg"}
													alt="Your Office People"
													height={24}
													layout="fixed"
												/>
											</button>
										)}
									</>
								</div>
							</div>
						</div>
					</Col>
					<Col className="text-md-end" md={4}>
						<p className="houschka mb-0 pb-1 fs-5">Private office from</p>
						<p className="houschka mb-0 pb-1 display-5">{newPrice}</p>
						<p style={{ color: "#707070" }} className="houschka fs-5">
							Per desk
						</p>
					</Col>
					<Col xs={12}>
						<div className="pt-4" style={{ borderTop: "2px solid #3AAA35" }}>
							<FacilitiesBlock
								array={featureList.slice(0, visibleFeaturesCount)}
							/>
							{visibleFeaturesCount < featureList.length && (
								<div className="text-center mb-3">
									<a
										style={{ cursor: "pointer" }}
										onClick={handleViewMore}
										className=" montserrat-bold text-green text-decoration-none"
									>
										View more
									</a>
								</div>
							)}
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default PropertyDetailHero;

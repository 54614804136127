import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { FaTrain } from "react-icons/fa";
import StationsTags from "./stations-tags";

const TubeDistance = ({ station, distanceKm, walkTime, tube, subwayLines }) => {
	const data = useStaticQuery(graphql`
		query {
			underground: wpMediaItem(title: { eq: "Underground_(no_text)" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
		}
	`);

	const tubeIcon = <StationsTags lines={subwayLines} station={station} />;
	const KM_TO_MILE = 0.621371;

	return (
		<>
			<div className="mb-3 d-flex align-items-center">
				{tube && <div className="pill-tags mb-1 mt-1">{tubeIcon}</div>}
				<p className="m-0 ms-2 mb-1 d-inline-block fs-6">
					{!tube && (
						<>
							<FaTrain />{" "}
						</>
					)}
					<span className="houschka">{station}</span> -{" "}
					{(distanceKm * KM_TO_MILE).toFixed(2)} mi ({walkTime} walk)
				</p>
			</div>
		</>
	);
};

export default TubeDistance;

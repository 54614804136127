import camelCase from "camelcase";
import gsap from "gsap";
import React from "react";
import { SiTransportforlondon } from "react-icons/si";
import { FaTrain } from "react-icons/fa"; // Import FaTrain icon
import slugify from "slugify";
import * as styles from "./stations-tags.module.scss";

// Import images
import nationalRail from "../images/assets/national-rail-logo.svg";
import thameslink from "../images/assets/thameslink.svg";
import londonOverground from "../images/assets/overground-roundel.svg";
import gatwickExpress from "../images/assets/gatwick-express.svg";
import heathrowExpress from "../images/assets/heathrow-express.svg";

const StationsTags = ({ lines, station }) => {
	const slugifiedStationLower = station
		? slugify(station?.toLowerCase()).replace(/[^_a-zA-Z0-9-]/g, "_")
		: "test";

	const colors = {
		bakerloo: { color: "#B36305", lineName: "Bakerloo" },
		central: { color: "#E32017", lineName: "Central" },
		circle: { color: "#FFD300", lineName: "Circle" },
		district: { color: "#00782A", lineName: "District" },
		"hammersmith-city": { color: "#F3A9BB", lineName: "Hammersmith & City" },
		jubilee: { color: "#A0A5A9", lineName: "Jubilee" },
		metropolitan: { color: "#9B0056", lineName: "Metropolitan" },
		northern: { color: "#000000", lineName: "Northern" },
		piccadilly: { color: "#003688", lineName: "Piccadilly" },
		victoria: { color: "#0098D4", lineName: "Victoria" },
		"waterloo-city": { color: "#95CDBA", lineName: "Waterloo & City" },
		dlr: { color: "#00A4A7", lineName: "DLR" },
		overground: { color: "#EE7C0E", lineName: "Overground" },
		tramlink: { color: "#84B817", lineName: "Tramlink" },
		"cable-car": { color: "#E21836", lineName: "Cable Car" },
		crossrail: { color: "#7156A5", lineName: "Crossrail" },
		elizabeth: { color: "#6950a1", lineName: "Elizabeth" },
	};

	const nationalRailObj = {
		c2c: { lineName: "C2C" },
		"great-northern": { lineName: "Great Northern" },
		"greater-anglia": { lineName: "Greater Anglia" },
		southeastern: { lineName: "Southeastern" },
		"chiltern-railways": { lineName: "Chiltern Railways" },
		southern: { lineName: "Southern" },
		"south-western-railway": { lineName: "South Western Railway" },
		"first-hull-trains": { lineName: "First Hull Trains" },
		"grand-central": { lineName: "Grand Central" },
		"london-north-eastern-railway": {
			lineName: "London North Eastern Railway",
		},
		lumo: { lineName: "Lumo" },
		"great-western-railway": { lineName: "Great Western Railway" },
		"avanti-west-coast": { lineName: "Avanti West Coast" },
		"west-midlands-trains": { lineName: "West Midlands Trains" },
	};

	const other = {
		thameslink: { lineName: "Thameslink", width: "35px" },
		"london-overground": { lineName: "London Overground", width: "35px" },
		"gatwick-express": { lineName: "Gatwick Express", width: "35px" },
		"heathrow-express": { lineName: "Heathrow Express", width: "35px" },
	};

	// Image components using <img> tags
	const nationalRailLogo = (
		<div className="text-start ps-1" style={{ width: "28px", height: "auto" }}>
			<img
				src={nationalRail}
				alt="National Rail"
				style={{ maxWidth: "18px" }}
				className="align-middle d-inline-block"
			/>
		</div>
	);
	const thameslinkLogo = (
		<div className="text-start ps-1" style={{ width: "28px" }}>
			<img
				src={thameslink}
				alt="Thameslink"
				style={{ maxWidth: "17px" }}
				className="align-middle ms-1 d-inline-block"
			/>
		</div>
	);
	const londonOvergroundLogo = (
		<div className="text-start ps-1" style={{ width: "28px" }}>
			<img
				src={londonOverground}
				alt="London Overground"
				style={{ maxWidth: "20px", maxHeight: "27px" }}
				className="align-middle d-inline-block"
			/>
		</div>
	);
	const gatwickExpressLogo = (
		<div className="text-start ps-1" style={{ width: "28px" }}>
			<img
				src={gatwickExpress}
				alt="Gatwick Express"
				style={{ maxWidth: "20px" }}
				className="align-middle d-inline-block"
			/>
		</div>
	);
	const heathrowExpressLogo = (
		<div className="text-start ps-1" style={{ width: "28px" }}>
			<img
				src={heathrowExpress}
				alt="Heathrow Express"
				style={{ maxWidth: "20px" }}
				className="d-inline-block me-1 align-middle"
			/>
		</div>
	);

	const renderSwitch = (line) => {
		switch (line) {
			case "thameslink":
				return thameslinkLogo;
			case "london-overground":
				return londonOvergroundLogo;
			case "gatwick-express":
				return gatwickExpressLogo;
			case "heathrow-express":
				return heathrowExpressLogo;
			default:
				return nationalRailLogo;
		}
	};

	const enterAnimation = (btn, maxWidthParam) => {
		gsap.to(btn, { maxWidth: `${maxWidthParam}px`, duration: 0.4 });
	};
	const exitAnimation = (btn) => {
		gsap.to(btn, { maxWidth: "35px", duration: 0.4 });
	};

	const handleMouseEnter = (e, lineClass, line) => {
		if (Object.keys(colors).includes(line)) {
			e.currentTarget.classList.add(styles[camelCase(line)]);
			e.currentTarget.classList.remove("border-pale-grey", "border");
		} else {
			e.currentTarget.classList.add("border");
			e.currentTarget.classList.add("border-pale-grey");
			e.currentTarget.classList.add(styles.basic);
		}
		const width = e.currentTarget.querySelector(".name-span").offsetWidth + 49;
		enterAnimation(`.${lineClass}`, width);
	};

	const handleMouseLeave = (e, lineClass, line) => {
		exitAnimation(`.${lineClass}`);
		e.currentTarget.classList.remove(
			"border",
			"border-pale-grey",
			styles[camelCase(line)],
			styles.basic
		);
		e.currentTarget.classList.add("border-pale-grey", "border");
	};

	const divClasses =
		"d-inline-block rounded-pill p-1 align-middle mt-0 mb-1 me-1 border border-pale-grey line-tag";
	const spanClasses = "name-span d-inline-block me-2 pe-2 my-auto";

	const iconSelector = (lineParam) => {
		if (Object.keys(colors).includes(lineParam)) {
			return (
				<SiTransportforlondon
					className="fs-4 ms-0 me-2 align-middle"
					style={{ color: colors[lineParam].color || "#6d6d6d" }}
				/>
			);
		} else if (Object.keys(nationalRailObj).includes(lineParam)) {
			return nationalRailLogo;
		} else if (Object.keys(other).includes(lineParam)) {
			return renderSwitch(lineParam);
		} else {
			// Use FaTrain icon for unknown lines
			return (
				<FaTrain
					className="fs-5  mb-1 me-2 align-middle"
					style={{ color: "#6d6d6d", marginLeft: "3px" }}
				/>
			);
		}
	};

	return (
		<>
			{lines?.map((line, i) => {
				return (
					<div
						key={i}
						className={`${line + "-" + slugifiedStationLower} ${divClasses} `}
						style={{ overflow: "hidden", maxWidth: "35px" }}
						onMouseEnter={(e) => {
							handleMouseEnter(e, line + "-" + slugifiedStationLower, line);
						}}
						onMouseLeave={(e) => {
							handleMouseLeave(e, line + "-" + slugifiedStationLower, line);
						}}
					>
						<div className="d-flex m-0 p-0">
							<div
								style={{
									width: "35px",
								}}
								className="pe-1"
							>
								{iconSelector(line)}
							</div>

							<span
								className={`${
									Object.keys(colors).includes(line)
										? styles[`${camelCase(line)}FontColor`]
										: "text-dark" // Use darker text for lines not using SiTransportforlondon
								} ${spanClasses} ${styles.nameSpan}`}
							>
								{colors[line]?.lineName ||
									nationalRailObj[line]?.lineName ||
									other[line]?.lineName ||
									line}
							</span>
						</div>
					</div>
				);
			})}
		</>
	);
};

export default StationsTags;

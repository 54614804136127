import { ValidationError, useForm } from "@formspree/react";
import "moment/locale/en-gb";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { v4 as uuidv4 } from "uuid";
import CartContext from "../context/CartContext";
import freeEmailDomains from "free-email-domains"; // Import the list of free email domains

function RequestQuoteFormMultiStep({ officeName, subject }) {
	const { numberOfPeople } = React.useContext(CartContext);
	const [state, handleSubmit] = useForm("xkgnnvor");

	const uniqueId = uuidv4();

	const [currentStep, setCurrentStep] = useState(1);
	const [formData, setFormData] = useState({
		"Office name": officeName || "",
		"Number of desks":
			numberOfPeople && !numberOfPeople.reset ? numberOfPeople.max : "",
		"Full name": "",
		Phone: "",
		"Work email": "",
		"Company name": "",
		"accept-the-policy": false,
		subject: subject || "",
	});

	const [errors, setErrors] = useState({});

	const formGroupStyles = "mb-2 mb-xl-4";
	const formInputStyles = " border-input";

	useEffect(() => {
		if (state.succeeded) {
			window.location.href = "thank-you-viewing";
		}
	}, [state.succeeded]);

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFormData((prev) => ({
			...prev,
			[name]: type === "checkbox" ? checked : value,
		}));
	};

	const validateStep1 = () => {
		let hasError = false;
		let stepErrors = {};

		if (!formData["Office name"]) {
			stepErrors.officeNameError = "Please enter the office name";
			hasError = true;
		}

		if (
			!formData["Number of desks"] ||
			Number(formData["Number of desks"]) <= 0
		) {
			stepErrors.numberOfDesksError =
				"Please enter a valid number of desks required";
			hasError = true;
		}

		setErrors(stepErrors);

		return !hasError;
	};

	const handleNext = (e) => {
		e.preventDefault();
		if (validateStep1()) {
			setCurrentStep(2);
		}
	};

	const onSubmit = (event) => {
		event.preventDefault();
		let hasError = false;
		let stepErrors = {};

		// Validate Full Name
		if (!formData["Full name"]) {
			stepErrors.fullNameError = "Please enter your full name";
			hasError = true;
		}

		// Validate Phone
		if (!formData["Phone"]) {
			stepErrors.phoneError = "Please enter your phone number";
			hasError = true;
		}

		// Validate Work Email
		if (!formData["Work email"]) {
			stepErrors.workEmailError = "Please enter your work email address";
			hasError = true;
		} else {
			const emailDomain = formData["Work email"].split("@")[1]?.toLowerCase();

			if (
				!emailDomain ||
				freeEmailDomains.includes(emailDomain) ||
				emailDomain.split(".").length < 2
			) {
				stepErrors.workEmailError = "Please enter a valid work email address";
				hasError = true;
			}
		}

		// Validate Company Name
		if (!formData["Company name"]) {
			stepErrors.companyNameError = "Please enter your company name";
			hasError = true;
		}

		// Validate Accept Policy
		if (!formData["accept-the-policy"]) {
			stepErrors.acceptPolicyError = "You must accept the privacy policy";
			hasError = true;
		}

		setErrors(stepErrors);

		if (hasError) {
			return;
		}

		handleSubmit(event);
	};

	return (
		<Form className="w-100" onSubmit={onSubmit}>
			{/* Step 1 */}
			<div style={{ display: currentStep === 1 ? "block" : "none" }}>
				<Form.Group className={`${formGroupStyles}`} controlId="Office name">
					<Form.Label className="houschka">Office name *</Form.Label>
					<Form.Control
						required
						name="Office name"
						placeholder="Office name"
						type="text"
						className={` green-border-bottom`}
						value={formData["Office name"]}
						onChange={handleChange}
					/>
					{errors.officeNameError && (
						<div style={{ color: "red", marginTop: "5px" }}>
							{errors.officeNameError}
						</div>
					)}
				</Form.Group>
				<Form.Group
					className={`${formGroupStyles}`}
					controlId="Number of desks"
				>
					<Form.Label className="houschka">Number of desks *</Form.Label>
					<Form.Control
						required
						name="Number of desks"
						placeholder="Enter the number of desks required"
						type="number"
						min="1"
						className={`${formInputStyles}`}
						value={formData["Number of desks"]}
						onChange={handleChange}
					/>
					{errors.numberOfDesksError && (
						<div style={{ color: "red", marginTop: "5px" }}>
							{errors.numberOfDesksError}
						</div>
					)}
				</Form.Group>
				<Button
					variant="secondary"
					className="px-3 py-2 w-100  text-light-black montserrat-bold"
					onClick={handleNext}
				>
					Next
				</Button>
			</div>

			{/* Step 2 */}
			<div style={{ display: currentStep === 2 ? "block" : "none" }}>
				<Form.Group className={`${formGroupStyles}`} controlId="Full name">
					<Form.Label className="houschka">Full name *</Form.Label>
					<Form.Control
						required
						name="Full name"
						placeholder="Adam Krill"
						type="text"
						className={`${formInputStyles}`}
						value={formData["Full name"]}
						onChange={handleChange}
					/>
					{errors.fullNameError && (
						<div style={{ color: "red", marginTop: "5px" }}>
							{errors.fullNameError}
						</div>
					)}
				</Form.Group>
				<Form.Group className={`${formGroupStyles}`} controlId="Phone">
					<Form.Label className="houschka">Phone *</Form.Label>
					<Form.Control
						required
						type="tel"
						name="Phone"
						placeholder="+44 203 016 0270"
						className={`${formInputStyles}`}
						value={formData["Phone"]}
						onChange={handleChange}
					/>
					{errors.phoneError && (
						<div style={{ color: "red", marginTop: "5px" }}>
							{errors.phoneError}
						</div>
					)}
				</Form.Group>

				<Form.Group className={`${formGroupStyles}`} controlId="Work email">
					<Form.Label className="houschka">Work email *</Form.Label>
					<Form.Control
						required
						name="Work email"
						type="email"
						placeholder="hello@yourofficepeople.co.uk"
						className={`${formInputStyles}`}
						value={formData["Work email"]}
						onChange={handleChange}
					/>
					{errors.workEmailError && (
						<div style={{ color: "red", marginTop: "5px" }}>
							{errors.workEmailError}
						</div>
					)}
				</Form.Group>

				<Form.Group className={`${formGroupStyles}`} controlId="Company name">
					<Form.Label className="houschka">Company name *</Form.Label>
					<Form.Control
						required
						name="Company name"
						placeholder="Your Office People"
						type="text"
						className={`${formInputStyles}`}
						value={formData["Company name"]}
						onChange={handleChange}
					/>
					{errors.companyNameError && (
						<div style={{ color: "red", marginTop: "5px" }}>
							{errors.companyNameError}
						</div>
					)}
				</Form.Group>

				<Form.Group className="mb-3" controlId="accept-the-policy">
					<Form.Check
						required
						label={
							<span>
								Upon submitting my message, I accept the{" "}
								<a
									className="text-decoration-underline"
									target="_blank"
									rel="noreferrer"
									href="/policies/privacy-notice"
								>
									privacy notice
								</a>
							</span>
						}
						className="w-auto"
						type="checkbox"
						name="accept-the-policy"
						checked={formData["accept-the-policy"]}
						onChange={handleChange}
					/>
					{errors.acceptPolicyError && (
						<div style={{ color: "red", marginTop: "5px" }}>
							{errors.acceptPolicyError}
						</div>
					)}
				</Form.Group>

				<Button
					variant="secondary"
					className="px-3 py-2 w-100  text-light-black montserrat-bold"
					type="submit"
					id="contact-send-btn"
					disabled={state.submitting}
				>
					Submit
				</Button>
			</div>

			{/* Hidden Fields (always included in the form) */}
			<Form.Group className={`${formGroupStyles} d-none`} controlId="subject">
				<Form.Control
					required
					value={formData.subject}
					name="subject"
					type="text"
					className={`${formInputStyles}`}
				/>
			</Form.Group>
			<ValidationError prefix="Subject" field="subject" errors={state.errors} />

			{/* Unique ID */}
			<Form.Group className="mb-3 d-none" controlId="uid">
				<Form.Control
					name="uid"
					value={uniqueId}
					placeholder="uid"
					type="text"
				/>
			</Form.Group>
			<ValidationError prefix="uid" field="uid" errors={state.errors} />
		</Form>
	);
}

export default RequestQuoteFormMultiStep;
